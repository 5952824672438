import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

import '../styles/pages/landing.css';
import imgLogo from '../images/logo-hfit.png';
import imgCheck from '../images/icon-check.png';

function Landing() {
  return (
    <div id="page-landing">
      <div className="content-wrapper">
        <div className="header">
          
          <div className="div-header-1">
            <div>
              <img className="img-logo" src={imgLogo} alt="" />
            </div>
            <div>
              <label className="header-titulo-1" >Atendimento remoto eficaz</label>
            </div>
            <div>
              <label className="header-titulo-2" >rápido e qualificado</label>
            </div>
          </div>
        
          <div className="div-header-2">
            <div className="div-header-2-col1">
              <div className="divLista">
                <img className="imgTexto" src={imgCheck} alt="" />
                <label className="label-title" >Baixo custo;</label>
              </div>
              <div className="divLista">
                <img className="imgTexto" src={imgCheck} alt="" />
                <label className="label-title" >Comodato de firewall;</label>
              </div>
              <div className="divLista">
                <img className="imgTexto" src={imgCheck} alt="" />
                <label className="label-title" >Antivírus de rede;</label>
              </div>
              <div className="divLista">
                <img className="imgTexto" src={imgCheck} alt="" />
                <label className="label-title" >Replicação de backup em nuvem e muito mais.</label>
              </div>
            </div>            
          </div>
        
        </div>

        <div className="bar-header">
            <Link to="/CreateOrcamento" className="enter-orcamento">
                <div className="button-bar-header">
                    Faça uma simulação de Proposta H-F.Ti agora.<br />
                    É só seguir esse link e responda as perguntas do Questionário Técnico
                </div>
            </Link>
          </div>

      </div>
      {/* <img className="img-logo" src={imgLogo} alt="" />
      <label className="header-titulo-1" >Atendimento remoto eficaz</label>
      <label className="header-titulo-2" >rápido e qualificado</label>
      <div className="content-wrapper">
        <div className="header">
          <img className="img" src={imgHeader} alt="" />
          
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default Landing;