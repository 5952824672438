import React from 'react';
import '../styles/components/Header.css'

export default function Header() {
    return (
        <header className="header">
            <div className="content-header">
                <h2>HIT Admin</h2>
                <h3>Gestão da Tabela de Valores Para Geração de Propostas</h3>
            </div>
        </header> 
    );
}