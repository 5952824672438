import React,
{
    FormEvent,
    //  ChangeEvent, 
    useState,
    //useEffect 
} from 'react';
import {
    Link,
    useHistory
} from 'react-router-dom';

import Footer from '../components/Footer';
import '../styles/pages/createOrcamento.css';

//import imgHeader from '../images/image-header-nt.jpg';
import imgLogo from '../images/logo-hfit.png';
import api from "../services/api";
import iOrcamento from '../interfaces/iOrcamento';

function CreateOrcamento() {
    var uuid = require('react-native-uuid');
    const history = useHistory();

    const [aviso, setAviso] = useState('');
    const [nome, setNome] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [retornar, setRetornar] = useState(false);
    const [serverWindows, setServerWindows] = useState('0');
    const [serverLinux, setServerLinux] = useState('0');
    const [desktopWindows, setDesktopWindows] = useState('0');
    const [desktopLinux, setDesktopLinux] = useState('0');
    const [desktopMac, setDesktopMac] = useState('0');
    const [contasEmail, setContasEmail] = useState('0');
    const [impressoras, setImpressoras] = useState('0');

    const [antenasWiFi, setAntenasWiFi] = useState('0');
    const [antenaDLink, setAntenaDLink] = useState(false);
    const [antenaTPLink, setAntenaTPLink] = useState(false);
    const [antenaIntelbras, setAntenaIntelbras] = useState(false);
    const [antenaAsus, setAntenaAsus] = useState(false);
    const [antenaUnifi, setAntenaUnifi] = useState(false);
    const [antenaOutros, setAntenaOutros] = useState('');
    const [massaDeDados, setMassaDeDados] = useState('');
    const [linksInternet, setLinksInternet] = useState('');
    const [linkVivoFibra, setLinkVivoFibra] = useState(false);
    const [linkClaroNet, setLinkClaroNet] = useState(false);
    const [linkTIMFibra, setLinkTIMFibra] = useState(false);
    const [linkDedicadoVivo, setLinkDedicadoVivo] = useState(false);
    const [linkDedicadoAlgar, setLinkDedicadoAlgar] = useState(false);
    const [linkDedicadoEmbratel, setLinkDedicadoEmbratel] = useState(false);
    const [linkDedicadoMundivox, setLinkDedicadoMundivox] = useState(false);
    const [linkDedicadoTelium, setLinkDedicadoTelium] = useState(false);
    const [linkDedicado, setLinkDedicado] = useState(false);
    const [link1Mb, setLink1Mb] = useState('');
    const [link2Mb, setLink2Mb] = useState('');
    const [link3Mb, setLink3Mb] = useState('');
    const [linkOutros, setLinkOutros] = useState('');
    const codigoGUID = uuid.v1();
    const [orcamento, setOrcamento] = useState<iOrcamento>();

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();
        if (nome === "") {
            setAviso(() => 'Por favor, nos diga seu nome ...')
        }
        else if (empresa === "") {
            setAviso(() => 'Por favor, nos diga o nome da sua empresa ...')
        }
        else if (email === "") {
            setAviso(() => 'Por favor, nos diga seu email. É apenas para controle interno ...')
        }
        else {
            
            const data = new FormData();

            data.append('nome', nome);
            data.append('controle', '');
            data.append('empresa', empresa);
            data.append('email', email);
            data.append('telefone', telefone);
            data.append('retornar', String(retornar));
            data.append('servidoresWindows', serverWindows);
            data.append('servidoresLinux', serverLinux);
            data.append('desktopWindows', desktopWindows);
            data.append('desktopLinux', desktopLinux);
            data.append('desktopMac', desktopMac);
            data.append('contasDeEmail', contasEmail);
            data.append('impressoras', impressoras);
            data.append('antenasWiFi', antenasWiFi);
            data.append('antenaDLink', String(antenaDLink));
            data.append('antenaTPLink', String(antenaTPLink));
            data.append('antenaIntelbras', String(antenaIntelbras));
            data.append('antenaAsus', String(antenaAsus));
            data.append('antenaUnifi', String(antenaUnifi));
            data.append('antenaOutros', antenaOutros);
            data.append('massaDeDados', massaDeDados);
            data.append('quantidadeLinks', linksInternet);
            data.append('linkVivoFibra', String(linkVivoFibra));
            data.append('linkClaroNet', String(linkClaroNet));
            data.append('linkTIMFibra', String(linkTIMFibra));
            data.append('linkDedicadoVivo', String(linkDedicadoVivo));
            data.append('linkDedicadoAlgar', String(linkDedicadoAlgar));
            data.append('linkDedicadoEmbratel', String(linkDedicadoEmbratel));
            data.append('linkDedicadoMundivox', String(linkDedicadoMundivox));
            data.append('linkDedicadoTelium', String(linkDedicadoTelium));
            data.append('linkDedicado', String(linkDedicado));
            data.append('link1Mbps', link1Mb);
            data.append('link2Mbps', link2Mb);
            data.append('link3Mbps', link3Mb);
            data.append('linkMbpsOutros', linkOutros);
            data.append('quantidadeVisitas', "0");
            data.append('valorServidores', "0");
            data.append('valorDesktops', "0");
            data.append('valorEmail', "0");
            data.append('valorImpressoras', "0");
            data.append('valorAntenas', "0");
            data.append('valorInternet', "0");
            data.append('valorBackup', "0");
            data.append('valorVisitas', "0");
            data.append('valorTotal', "0");
            data.append('posicao', "PENDENTE");
            data.append('criadoEm', "");
            data.append('enviadoEm', "");
            data.append('validadoEm', "");
            data.append('validadoPor', "");
            data.append('codigoGUID', codigoGUID);

            await api.post('Orcamento/NovoOrcamento', data).then(response => {
                setOrcamento(response.data)
            });

            history.push(`/Orcamento/${codigoGUID}`);
        }
    }

    return (
        <div id="page-orcamento">
            <div className="content-wrapper">
                <div className="header">
                    <div className="div-header-1">
                        <div>
                            <Link to="/" >
                                <img className="img-logo" src={imgLogo} alt="" />
                            </Link>
                        </div>
                        <div>
                            <label className="header-titulo-1" >Atendimento remoto eficaz</label>
                        </div>
                        <div>
                            <label className="header-titulo-2" >rápido e qualificado</label>
                        </div>
                    </div>
                    <div className="div-header-2">
                        <div className="div-header-2-col1">
                            <div>
                                <label className="label-cadastro-title" >Para elaborar uma Proposta, informe seu dados</label>
                            </div>
                            <div>
                                <input className="input-text-cad"
                                    type="text"
                                    id="nome"
                                    name="nome" value={nome}
                                    placeholder="Nome"
                                    onChange={event => setNome(event.target.value)} />

                            </div>
                            <div>
                                <input className="input-text-cad"
                                    type="text"
                                    id="empresa"
                                    name="empresa" value={empresa}
                                    placeholder="Empresa"
                                    onChange={event => setEmpresa(event.target.value)} />
                            </div>
                            <div>
                                <input className="input-text-cad"
                                    type="text"
                                    id="email"
                                    name="email" value={email}
                                    placeholder="Email"
                                    onChange={event => setEmail(event.target.value)} />
                            </div>
                        </div>

                        <div className="div-header-2-col2">

                            <div>
                                <input className="input-checkbox"
                                    type="checkbox"
                                    id="retornar"
                                    name="retornar"
                                    value="retornar"
                                    checked={retornar}
                                    onChange={() => setRetornar(retornar ? false : true)} />
                                <label className="label-cadastro-title">Desejo receber um contato para</label>
                            </div>

                            <div>
                                <label className="label-cadastro-title">mais informações, no telefone abaixo .. </label>
                            </div>

                            <div>
                                <input className="input-text-cad"
                                    type="text"
                                    id="telefone"
                                    name="telefone" value={telefone}
                                    placeholder="Telefone"
                                    onChange={event => setTelefone(event.target.value)} />
                            </div>

                            <div>
                                <label className="label-cadastro-title">Política de Privacidade</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="bar-header">
                    FORMULÁRIO PARA ELABORAÇÃO DE PROPOSTA SIMPLIFICADA - PLANO H-F.Ti
          </div>
                {/* <main> */}
                <form className="create-orcamento" onSubmit={handleSubmit}>

                    <div className="form-group">
                        {/* Servidores */}
                        <label className="label-title-item">
                            1 - Quantidade de Servidores que a empresa possui (físico, virtual, nuvem)?
          </label>
                        {/* Server Windows */}
                        <label className="label-title-superior">{!serverWindows ? "0" : serverWindows}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeServidorWindows"
                                type="range"
                                min="0"
                                max="20"
                                step="1"
                                defaultValue="0"
                                onChange={event => setServerWindows(event.target.value)} />
                            <label className="label-range-title">20</label>
                            <label className="label-title">Windows</label>
                        </div>
                        {/* Server Linux */}
                        <label className="label-title-superior">{!serverLinux ? "0" : serverLinux}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeServidorLinux"
                                type="range"
                                min="0"
                                max="20"
                                step="1"
                                defaultValue="0"
                                onChange={event => setServerLinux(event.target.value)} />
                            <label className="label-range-title">20</label>
                            <label className="label-title">Linux</label>
                        </div>
                        {/* DESKTOPS */}
                        <label className="label-title-item">
                            2 - Quantidade de Notebooks / Desktops / Macs?
          </label>
                        {/* Desktop Windows */}
                        <label className="label-title-superior">{!desktopWindows ? "0" : desktopWindows}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeDesktopWindows"
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                defaultValue="0"
                                onChange={event => setDesktopWindows(event.target.value)} />
                            <label className="label-range-title">100</label>
                            <label className="label-title">Windows</label>
                        </div>
                        {/* Desktop Linux */}
                        <label className="label-title-superior">{!desktopLinux ? "0" : desktopLinux}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeDesktopLinux"
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                defaultValue="0"
                                onChange={event => setDesktopLinux(event.target.value)} />
                            <label className="label-range-title">100</label>
                            <label className="label-title">Linux</label>
                        </div>
                        {/* Desktop Mac */}
                        <label className="label-title-superior">{!desktopMac ? "0" : desktopMac}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeDesktopMac"
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                defaultValue="0"
                                onChange={event => setDesktopMac(event.target.value)} />
                            <label className="label-range-title">100</label>
                            <label className="label-title">Mac</label>
                        </div>

                        {/* CONTAS DE EMAIL */}
                        <label className="label-title-item">
                            3 - Quantidade de contas de e-mail?
          </label>
                        {/* Contas de email */}
                        <label className="label-title-superior">{!contasEmail ? "0" : contasEmail}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeContasDeEmail"
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                defaultValue="0"
                                onChange={event => setContasEmail(event.target.value)} />
                            <label className="label-range-title">100</label>
                            {/* <label className="label-title">( {!contasEmail ? "0" : contasEmail} )</label> */}
                        </div>

                        {/* IMPRESSORAS */}
                        <label className="label-title-item">
                            4 - Quantidade de impressoras?
          </label>
                        {/* Impressoras */}
                        <label className="label-title-superior">{!impressoras ? "0" : impressoras}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeImpressoras"
                                type="range"
                                min="0"
                                max="10"
                                step="1"
                                defaultValue="0"
                                onChange={event => setImpressoras(event.target.value)} />
                            <label className="label-range-title">10</label>
                            {/* <label className="label-title">( {!impressoras ? "0" : impressoras} )</label> */}
                        </div>

                        {/* ANTENAS DE WIFI */}
                        <label className="label-title-item">
                            5 - Quantidade de antenas WiFi?
          </label>
                        {/* Antenas de WiFi */}
                        <label className="label-title-superior">{!antenasWiFi ? "0" : antenasWiFi}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeWiFi"
                                type="range"
                                min="0"
                                max="10"
                                step="1"
                                defaultValue="0"
                                onChange={event => setAntenasWiFi(event.target.value)} />
                            <label className="label-range-title">10</label>
                            {/* <label className="label-title">( {!antenasWiFi ? "0" : antenasWiFi} )</label> */}
                        </div>

                        {/* COMBOS DE ANTENAS */}
                        <label className="label-range-title">Modelos de Antenas WiFi:</label>
                        {/* D-Link */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="antenaDLink"
                                name="antenaDLink"
                                value="D-Link"
                                checked={antenaDLink}
                                onChange={() => setAntenaDLink(antenaDLink ? false : true)} />
                            <label className="label-range-title">D-Link</label>
                        </div>
                        {/* TP-Link */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="antenaTPLink"
                                name="antenaTPLink"
                                checked={antenaTPLink}
                                onChange={() => setAntenaTPLink(antenaTPLink ? false : true)} />
                            <label className="label-range-title">TP-Link</label>
                        </div>
                        {/* Intelbras */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="antenaIntelbras"
                                name="antenaIntelbras"
                                checked={antenaIntelbras}
                                onChange={() => setAntenaIntelbras(antenaIntelbras ? false : true)} />
                            <label className="label-range-title">Intelbras</label>
                        </div>
                        {/* Asus */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="antenaAsus"
                                name="antenaAsus"
                                checked={antenaAsus}
                                onChange={() => setAntenaAsus(antenaAsus ? false : true)} />
                            <label className="label-range-title">Asus</label>
                        </div>
                        {/* Unifi */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="antenaUnifi"
                                name="antenaUnifi"
                                checked={antenaUnifi}
                                onChange={() => setAntenaUnifi(antenaUnifi ? false : true)} />
                            <label className="label-range-title">Unifi</label>
                        </div>

                        {/* OUTROS MODELOS DE ANTENA WIFI */}
                        <div className="divInputText">
                            {/* <label className="label-range-title">Outros:</label> */}
                            <input className="input-text"
                                type="text"
                                id="outrasAntenas"
                                name="outrasAntenas"
                                placeholder="Outros"
                                onChange={event => setAntenaOutros(event.target.value)} />
                        </div>

                        {/* MASSA DE DADOS */}
                        <label className="label-title-item">
                            6 - Quantidade de massa de dados para backup full e incremental?
          </label>
                        {/* Massa de Dados */}
                        <label className="label-title-superior">{!massaDeDados ? "0" : massaDeDados} GB</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeMassaDeDados"
                                type="range"
                                min="0"
                                max="1000"
                                step="50"
                                defaultValue="0"
                                onChange={event => setMassaDeDados(event.target.value)} />
                            <label className="label-range-title">1000 GB (1TB)</label>
                            {/* <label className="label-title">( {!massaDeDados ? "0" : massaDeDados} )</label> */}
                        </div>

                        {/* LINKS DE INTERNET */}
                        <label className="label-title-item">
                            7 - Quantidade de links de internet?
          </label>
                        {/* Links de Internet */}
                        <label className="label-title-superior">{!linksInternet ? "0" : linksInternet}</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeLinksDeInternet"
                                type="range"
                                min="0"
                                max="3"
                                step="1"
                                defaultValue="0"
                                onChange={event => setLinksInternet(event.target.value)} />
                            <label className="label-range-title" >3</label>
                            {/* <label className="label-title">( {!linksInternet ? "0" : linksInternet} )</label> */}
                        </div>

                        {/* COMBOS DE LINKS */}
                        <label className="label-range-title">Tipos de link:</label>
                        {/* VIVO FIBRA */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkVivoFibra"
                                name="linkVivoFibra"
                                checked={linkVivoFibra}
                                onChange={() => setLinkVivoFibra(linkVivoFibra ? false : true)} />
                            <label className="label-range-title">Vivo Fibra</label>
                        </div>

                        {/* CLARO NET */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkClaroNet"
                                name="linkClaroNet"
                                checked={linkClaroNet}
                                onChange={() => setLinkClaroNet(linkClaroNet ? false : true)} />
                            <label className="label-range-title">Claro (Net)</label>
                        </div>

                        {/* TIM FIBRA */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkTIMFibra"
                                name="linkTIMFibra"
                                checked={linkTIMFibra}
                                onChange={() => setLinkTIMFibra(linkTIMFibra ? false : true)} />
                            <label className="label-range-title">TIM Fibra</label>
                        </div>

                        {/* LINK DEDICADO VIVO */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkDedicadoVivo"
                                name="linkDedicadoVivo"
                                checked={linkDedicadoVivo}
                                onChange={() => setLinkDedicadoVivo(linkDedicadoVivo ? false : true)} />
                            <label className="label-range-title">Link Dedicado Vivo</label>
                        </div>

                        {/* LINK DEDICADO ALGAR */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkDedicadoAlgar"
                                name="linkDedicadoAlgar"
                                checked={linkDedicadoAlgar}
                                onChange={() => setLinkDedicadoAlgar(linkDedicadoAlgar ? false : true)} />
                            <label className="label-range-title">Link Dedicado Algar</label>
                        </div>

                        {/* LINK DEDICADO EMBRATEL */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkDedicadoEmbratel"
                                name="linkDedicadoEmbratel"
                                checked={linkDedicadoEmbratel}
                                onChange={() => setLinkDedicadoEmbratel(linkDedicadoEmbratel ? false : true)} />
                            <label className="label-range-title">Link Dedicado Embratel</label>
                        </div>

                        {/* LINK DEDICADO MUNDIVOX */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkDedicadoMundivox"
                                name="linkDedicadoMundivox"
                                checked={linkDedicadoMundivox}
                                onChange={() => setLinkDedicadoMundivox(linkDedicadoMundivox ? false : true)} />
                            <label className="label-range-title">Link Dedicado Mundivox</label>
                        </div>

                        {/* LINK DEDICADO TELIUM */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkDedicadoTelium"
                                name="linkDedicadoTelium"
                                checked={linkDedicadoTelium}
                                onChange={() => setLinkDedicadoTelium(linkDedicadoTelium ? false : true)} />
                            <label className="label-range-title">Link Dedicado Telium</label>
                        </div>

                        {/* LINK DEDICADO */}
                        <div className="divCheckBox">
                            <input className="input-checkbox"
                                type="checkbox"
                                id="linkDedicado"
                                name="linkDedicado"
                                checked={linkDedicado}
                                onChange={() => setLinkDedicado(linkDedicado ? false : true)} />
                            <label className="label-range-title">Link Dedicado</label>
                        </div>

                        {/* LINKS DE INTERNET VELOCIDADE */}
                        <label className="label-title-item">
                            Quantidade Mbps possui cada Link?
          </label>
                        {/* Links de Internet Velocidade*/}
                        <label className="label-title-superior">{!link1Mb ? "0" : link1Mb} Mbps</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeLink1Mb"
                                type="range"
                                min="0"
                                max="600"
                                step="50"
                                defaultValue="0"
                                onChange={event => setLink1Mb(event.target.value)} />
                            <label className="label-range-title">600</label>
                            {/* <label className="label-title">( {!link1Mb ? "0" : link1Mb} )</label> */}
                        </div>

                        <label className="label-title-superior">{!link2Mb ? "0" : link2Mb} Mbps</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeLink2Mb"
                                type="range"
                                min="0"
                                max="600"
                                step="50"
                                defaultValue="0"
                                onChange={event => setLink2Mb(event.target.value)} />
                            <label className="label-range-title">600</label>
                            {/* <label className="label-title">( {!link2Mb ? "0" : link2Mb} )</label> */}
                        </div>

                        <label className="label-title-superior">{!link3Mb ? "0" : link3Mb} Mbps</label>
                        <div className="div-form-group">
                            <label className="label-range-title">0</label>
                            <input className="input-range"
                                id="rangeLink3Mb"
                                type="range"
                                min="0"
                                max="600"
                                step="50"
                                defaultValue="0"
                                onChange={event => setLink3Mb(event.target.value)} />
                            <label className="label-range-title">600</label>
                            {/* <label className="label-title">( {!link3Mb ? "0" : link3Mb} )</label> */}
                        </div>

                        {/* OUTRA VELOCIDADE DE LINKS */}
                        <div className="divInputText">
                            {/* <label className="label-range-title">Outros:</label> */}
                            <input className="input-text"
                                type="text"
                                id="outrasVelocidades"
                                name="outrasVelocidades"
                                placeholder="Outros"
                                onChange={event => setLinkOutros(event.target.value)} />
                        </div>

                    </div>
                    <div className="aviso">
                        <label className="label-range-title">{aviso}</label>
                    </div>

                    <div className="divButton">
                        <button className="confirm-button" type="submit">
                            GERAR PROPOSTA H-F.Ti
              </button>
                    </div>
                </form>
                {/* </main> */}

            </div>
          <Footer />
        </div>
    );
}

export default CreateOrcamento;