import React from 'react';
import '../styles/components/Footer.css'
import imgFacebook from '../images/icone-facebook.svg'
import imgInstagram from '../images/icone-instagram.svg'

export default function Footer() {
    return (
        <div className="footer">
            <div className="divImg">
                <img className="img" src={imgFacebook} alt="" />
                <img className="img" src={imgInstagram} alt="" />
            </div>
            <div className="endereco">
            Rua Caio Graco, 747 - Vila Romana - CEP 05044-000 - São Paulo - SP  |  Tel.: (11) 2495-0624  |  cth@humanit-ti.com
            </div>
        </div>
    );
}