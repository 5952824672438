import React, {FormEvent, useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';

import '../styles/pages/hitAdmin.css';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import api from "../services/api";
import iValoresVisitas from '../interfaces/iValoresVisitas';

interface valoresParams {
    id: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(0),
      minWidth: 120,
      marginBotton: theme.spacing(3),
    },
  }));

export default function ValoresVisitasEditar(){
    const history = useHistory();
    const [retornoAPI,setRetornoAPI] = useState('');
    const params = useParams<valoresParams>();
    const classes = useStyles();

    const [valorVisita, setValorVisita] = useState<iValoresVisitas>();
    const [IdValoresVisitas, setIdValoresVisitas] = useState('');
    const [usuariosMinimo, setUsuariosMinimo] = useState('');
    const [usuariosMaximo, setUsuariosMaximo] = useState('');
    const [horasMes, setHorasMes] = useState('');
    const [valorHora, setValorHora] = useState('');
    const [valorDeslocamento, setValorDeslocamento] = useState('');
    const [valorAcrescimo, setValorAcrescimo] = useState('');
    const [posicao, setPosicao] = useState('');
    const [codigoGUID, setCodigoGUID] = useState('');

    useEffect(() => {
        async function obterValorVisita()
        {
            await api.post(`Orcamento/ObtemValoresVisitas?idValorAdicional=${params.id}`).then(response => {
                setValorVisita(response.data[0]);
                console.log(response.data[0]);
                setIdValoresVisitas(response.data[0].IdValoresVisitas);
                setUsuariosMinimo(response.data[0].usuariosMinimo);
                setUsuariosMaximo(response.data[0].usuariosMaximo);
                setHorasMes(response.data[0].horasMes);
                setValorHora(response.data[0].valorHora.toFixed(2));
                setValorDeslocamento(response.data[0].valorDeslocamento.toFixed(2));
                setValorAcrescimo(response.data[0].valorAcrescimo.toFixed(2));
                setPosicao(response.data[0].posicao);
                setCodigoGUID(response.data[0].codigoGUID);
            })
        }
        obterValorVisita();
    },[params.id]);

    function CheckNumber(e: any){
        var txtValue = e.value;
    
        var pos = txtValue.length;
        var posI = pos - 1;
        var valor = txtValue.substring(posI, pos);
    
        if (//valor !== ',' &&
            valor !== '0' &&
            valor !== '1' &&
            valor !== '2' &&
            valor !== '3' &&
            valor !== '4' &&
            valor !== '5' &&
            valor !== '6' &&
            valor !== '7' &&
            valor !== '8' &&
            valor !== '9') {
            e.value = txtValue.substring(0, txtValue.length - 1);
        }
    }

    const handleChange = (event: any) => {
        if(event.target.value === 1)
        {
            setPosicao("ATIVO");
        }
        else
        {
            setPosicao("INATIVO");
        }
    };

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();
        if (usuariosMinimo === '' || usuariosMaximo === '' || horasMes === '' || valorHora === '' || valorDeslocamento === '')
        {
            alert('Preencha todos os Campos!');
        }
        else
        {
            const data = new FormData();
            data.append('IdValoresVisitas', IdValoresVisitas);
            data.append('usuariosMinimo', usuariosMinimo);
            data.append('usuariosMaximo', usuariosMaximo);
            data.append('horasMes', horasMes);
            data.append('valorHora', valorHora.replace(".",","));
            data.append('valorDeslocamento', valorDeslocamento.replace(".",","));
            data.append('valorAcrescimo', valorAcrescimo.replace(".",","));
            data.append('posicao', posicao);
            data.append('codigoGUID', codigoGUID);

            await api.post('Orcamento/AtualizarValoresVisitas',data).then(response => {
                setRetornoAPI(response.data);
                console.log(response.data);
                //alert(response.data);
                if(response.data === "OK")
                {
                    alert("Salvo com Sucesso!");
                    history.push(`/ValoresVisitas`);
                }
                else
                {
                    alert(response.data);
                }
            })
        }
    }

if (!valorVisita){ }

    return (
        <div className="admin-window">
            <Header />
            <div className="content-main">
                <Sidebar />
                <div className="content-area">
                    <div>
                        <form onSubmit={handleSubmit}>
                            <React.Fragment>
                                <Typography variant="h6" gutterBottom>
                                EDITAR VALOR VISITAS MÊS
                                </Typography>
                                <hr></hr><br />
                                <Grid container spacing={3}>
                                    {/* MINIMO */}
                                    <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="usuariosMin"
                                        name="usuariosMin"
                                        label="Usuários Mínimo"
                                        fullWidth
                                        onKeyPress={e => CheckNumber(e.target)}
                                        value={usuariosMinimo}
                                        onChange={e => setUsuariosMinimo(e.target.value)}
                                    />
                                    </Grid>
                                    {/* MAXIMO */}
                                    <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="usuariosMax"
                                        name="usuariosMax"
                                        label="Usuários Máximo"
                                        fullWidth
                                        onKeyPress={e => CheckNumber(e.target)}
                                        value={usuariosMaximo}
                                        onChange={e => setUsuariosMaximo(e.target.value)}
                                    />
                                    </Grid>
                                    {/* HORAS MES */}
                                    <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="horasM"
                                        name="horasM"
                                        label="Horas Mês"
                                        fullWidth
                                        onKeyPress={e => CheckNumber(e.target)}
                                        value={horasMes}
                                        onChange={e => setHorasMes(e.target.value)}
                                    />
                                    </Grid>
                                    {/* VALOR HORA */}
                                    <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="valorH"
                                        name="valorH"
                                        label="Valor Hora"
                                        fullWidth
                                        onKeyPress={e => CheckNumber(e.target)}
                                        value={valorHora}
                                        onChange={e => setValorHora(e.target.value.replace(".",","))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                          }}
                                    />
                                    </Grid>
                                    {/* DESLOCAMENTO */}
                                    <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="valorD"
                                        name="valorD"
                                        label="Valor Deslocamento"
                                        fullWidth
                                        onKeyPress={e => CheckNumber(e.target)}
                                        value={valorDeslocamento}
                                        onChange={e => setValorDeslocamento(e.target.value.replace(".",","))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                          }}
                                    />
                                    </Grid>
                                    {/* ACRESCIMO */}
                                    <Grid item xs={12} sm={6}>
                                    <TextField disabled
                                        required
                                        id="valorA"
                                        name="valorA"
                                        label="Valor Acréscimo"
                                        fullWidth
                                        onKeyPress={e => CheckNumber(e.target)}
                                        value={valorAcrescimo}
                                        onChange={e => setValorAcrescimo(e.target.value.replace(".",","))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                          }}
                                    />
                                    </Grid>
                                    {/* POSICAO */}
                                    <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Posicao</InputLabel>
                                    <Select className={classes.formControl}
                                        labelId="selectPosicao"
                                        id="selectPosicao"
                                        value={posicao === "ATIVO"? 1 : 0 }
                                        onChange={handleChange}
                                        >
                                        <MenuItem value={1}>ATIVO</MenuItem>
                                        <MenuItem value={0}>INATIVO</MenuItem>
                                    </Select>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    <ButtonGroup aria-label="outlined primary button group">
                                        <Button color="primary" type="submit">Salvar Valores</Button>
                                    </ButtonGroup>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}