import React , { FormEvent, useState, useEffect}from 'react';
import '../styles/pages/hitAdmin.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import iValoresItens from '../interfaces/iValoresItens';
import api from '../services/api';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';

export default function ValoresTabela() {

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        margintop: theme.spacing(2),
        },
    },
    }));
const classes = useStyles();

const [retornoAPI,setRetornoAPI] = useState('');
const [valoresItens, setValoresItens] = useState<iValoresItens>();

const [IdValores, setIdValores] = useState('');
const [posicao, setPosicao] = useState('');
const [valorServidor, setValorServidor] = useState('');
const [valorDesktop, setValorDesktop] = useState('');
const [valorEmail, setValorEmail] = useState('');
const [valorImpressora, setValorImpressora] = useState('');
const [valorAntenaWiFi, setValorAntenaWiFi] = useState('');
const [valorLinkInternet, setValorLinkInternet] = useState('');
const [valorBackup, setValorBackup] = useState('');
const [codigoGUID, setCodigoGUID] = useState('');

useEffect(() => {
    async function carregaValores(){
        await api.post(`Orcamento/ObtemValoresItens`).then(response => {
            //console.log(response.data);
            setValoresItens(response.data);
            setIdValores(response.data.IdValores);
            setPosicao(response.data.posicao);
            setValorServidor(response.data.valorServidor.toFixed(2).replace(".",","));
            setValorDesktop(response.data.valorDesktop.toFixed(2).replace(".",","));
            setValorEmail(response.data.valorEmail.toFixed(2).replace(".",","));
            setValorImpressora(response.data.valorImpressora.toFixed(2).replace(".",","));
            setValorAntenaWiFi(response.data.valorAntenaWiFi.toFixed(2).replace(".",","));
            setValorLinkInternet(response.data.valorLinkInternet.toFixed(2).replace(".",","));
            setValorBackup(response.data.valorBackup.toFixed(2).replace(".",","));
            setCodigoGUID(response.data.codigoGUID);
        })
    }
    carregaValores();
}, [])

if(!valoresItens){
    // return <p>Carregando...</p>;
}

function CheckNumber(e: any){
    var txtValue = e.value;

    var pos = txtValue.length;
    var posI = pos - 1;
    var valor = txtValue.substring(posI, pos);

    if (valor !== ',' &&
        valor !== '0' &&
        valor !== '1' &&
        valor !== '2' &&
        valor !== '3' &&
        valor !== '4' &&
        valor !== '5' &&
        valor !== '6' &&
        valor !== '7' &&
        valor !== '8' &&
        valor !== '9') {
        e.value = txtValue.substring(0, txtValue.length - 1);
    }
}

async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if(valorServidor === '' || 
    valorDesktop === '' || 
    valorEmail === '' || 
    valorImpressora == '' ||
    valorAntenaWiFi == '' ||
    valorLinkInternet == '' ||
    valorBackup == '')
    {
        alert('Preencha todos os Campos');
    }
    else
    {
        const data = new FormData();
        data.append('IdValores', IdValores);
        data.append('posicao', posicao);
        data.append('valorServidor', valorServidor);
        data.append('valorDesktop', valorDesktop);
        data.append('valorEmail', valorEmail);
        data.append('valorImpressora', valorImpressora);
        data.append('valorAntenaWiFi', valorAntenaWiFi);
        data.append('valorLinkInternet', valorLinkInternet);
        data.append('valorBackup', valorBackup);
        data.append('codigoGUID', codigoGUID);

        await api.post('Orcamento/AtualizarValoresItens', data).then(response => {
            setRetornoAPI(response.data)
            console.log(response.data);
            if(response.data === "OK")
            {
                alert("Valores Salvos com Sucesso!");
            }
            else
            {
                alert("Não foi possível salvar, tente Novamente.");
            }
        });
    }
}
    return (
        <div className="admin-window">
            <Header />
            <div className="content-main">
                <Sidebar />
                <div className="content-area">
                    <div>TABELA DE VALORES ITENS DA PROPOSTA</div>
                    <br /><hr /><br />
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="divForm">
                        <TextField 
                        name="textValorServidor"
                        required 
                        fullWidth
                        onKeyPress={e => CheckNumber(e.target)}
                        id="outlined-basic" 
                        label="SERVIDORES" 
                        variant="outlined" 
                        onChange={e => setValorServidor(e.target.value)}
                        helperText="valor referente a cada Servidor (Windows ou Linux)"
                        value={valorServidor} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                    </div>
                    <div className="divForm">
                        <TextField 
                        name="textValorDesktop"
                        required 
                        fullWidth
                        onKeyPress={e => CheckNumber(e.target)}
                        id="outlined-basic" 
                        label="DESKTOPs" 
                        variant="outlined" 
                        onChange={e => setValorDesktop(e.target.value)}
                        helperText="valor referente a cada Desktop (Windows,Linux ou Mac)"
                        value={valorDesktop} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                    </div>
                    <div className="divForm">
                        <TextField 
                        name="textValorEmail"
                        required 
                        fullWidth
                        onKeyPress={e => CheckNumber(e.target)}
                        id="outlined-basic" 
                        label="EMAILs" 
                        variant="outlined" 
                        onChange={e => setValorEmail(e.target.value)}
                        helperText="valor referente a cada conta de Email"
                        value={valorEmail} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                    </div>
                    <div className="divForm">
                        <TextField 
                        name="textValorImpressora"
                        required 
                        fullWidth
                        onKeyPress={e => CheckNumber(e.target)}
                        id="outlined-basic" 
                        label="IMPRESSORAS" 
                        variant="outlined" 
                        onChange={e => setValorImpressora(e.target.value)}
                        helperText="valor referente a cada Impressora"
                        value={valorImpressora} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                    </div>
                    <div className="divForm">
                        <TextField 
                        name="textValorAntenaWiFi"
                        required 
                        fullWidth
                        onKeyPress={e => CheckNumber(e.target)}
                        id="outlined-basic" 
                        label="ANTENAS WIFI" 
                        variant="outlined" 
                        onChange={e => setValorAntenaWiFi(e.target.value)}
                        helperText="valor referente a cada Antena de WiFi"
                        value={valorAntenaWiFi} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                    </div>
                    <div className="divForm">
                        <TextField 
                        name="textValorLinkInternet"
                        required 
                        fullWidth
                        onKeyPress={e => CheckNumber(e.target)}
                        id="outlined-basic" 
                        label="LINKS DE INTERNET" 
                        variant="outlined" 
                        onChange={e => setValorLinkInternet(e.target.value)}
                        helperText="valor referente a cada Link de Internet"
                        value={valorLinkInternet} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                    </div>
                    <div className="divForm">
                        <TextField 
                        name="textValorBackup"
                        required 
                        fullWidth
                        onKeyPress={e => CheckNumber(e.target)}
                        id="outlined-basic" 
                        label="BACKUP DE DADOS" 
                        variant="outlined" 
                        onChange={e => setValorBackup(e.target.value)}
                        helperText="valor referente a cada GB de Massa de Backup"
                        value={valorBackup} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                    </div>
                    <div className="divForm">
                    <ButtonGroup aria-label="outlined primary button group">
                        <Button color="primary" type="submit">Salvar Valores</Button>
                    </ButtonGroup>
                    </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}