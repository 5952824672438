import React, {FormEvent, useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';

import '../styles/pages/hitAdmin.css';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import api from "../services/api";
import iValoresAdicionais from '../interfaces/iValoresAdicionais';

interface valoresParams {
    id: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(0),
      minWidth: 120,
      marginBotton: theme.spacing(3),
    },
  }));

export default function ValoresAdicionaisCadastro(){
    const history = useHistory();
    const [retornoAPI,setRetornoAPI] = useState('');
    const classes = useStyles();
    
    const [idValorAdicional, setIdValorAdicional] = useState('');
    const [descricaoItem, setDescricaoItem] = useState('');
    const [valorItem, setValorItem] = useState('');
    const [posicao, setPosicao] = useState('');
    const [codigoGUID, setCodigoGUID] = useState('');

    function CheckNumber(e: any){
        var txtValue = e.value;
    
        var pos = txtValue.length;
        var posI = pos - 1;
        var valor = txtValue.substring(posI, pos);
    
        if (valor !== ',' &&
            valor !== '0' &&
            valor !== '1' &&
            valor !== '2' &&
            valor !== '3' &&
            valor !== '4' &&
            valor !== '5' &&
            valor !== '6' &&
            valor !== '7' &&
            valor !== '8' &&
            valor !== '9') {
            e.value = txtValue.substring(0, txtValue.length - 1);
        }
    }

    const handleChange = (event: any) => {
        if(event.target.value === 1)
        {
            setPosicao("ATIVO");
        }
        else
        {
            setPosicao("INATIVO");
        }
      };

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();
        if (descricaoItem === '' || valorItem === '')
        {
            alert('Preencha todos os Campos!');
        }
        else
        {
            const data = new FormData();
            data.append('IdValorAdicional', idValorAdicional);
            data.append('descricaoItem', descricaoItem);
            data.append('valorItem', valorItem.replace(".",","));
            data.append('posicao', posicao);
            data.append('codigoGUID', codigoGUID);

            await api.post('Orcamento/CadastrarValoresAdicionais', data).then(response => {
                setRetornoAPI(response.data);
                console.log(response.data);
                if(response.data === "OK")
                {
                    alert("Salvo com Sucesso!");
                    history.push(`/ValoresAdicionais`);
                }
                else
                {
                    alert("Registro não foi Salvo!");
                }
            });

        }
    }

    return (
        <div className="admin-window">
            <Header />
            <div className="content-main">
                <Sidebar />
                <div className="content-area">
                    <div>
                        <form onSubmit={handleSubmit}>
                            <React.Fragment>
                                <Typography variant="h6" gutterBottom>
                                CADASTRAR VALOR ADICIONAL
                                </Typography>
                                <hr></hr><br />
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="descricaoitem"
                                        name="descricaoitem"
                                        label="Descrição do Item"
                                        fullWidth
                                        value={descricaoItem}
                                        onChange={e => setDescricaoItem(e.target.value)}
                                    />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        id="valoritem"
                                        name="valoritem"
                                        label="Valor do Item"
                                        fullWidth
                                        onKeyPress={e => CheckNumber(e.target)}
                                        value={valorItem}
                                        onChange={e => setValorItem(e.target.value.replace(".",","))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                          }}
                                    />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Posicao</InputLabel>
                                    <Select className={classes.formControl}
                                        labelId="selectPosicao"
                                        id="selectPosicao"
                                        value={posicao === "ATIVO"? 1 : 0 }
                                        onChange={handleChange}
                                        >
                                        <MenuItem value={1}>ATIVO</MenuItem>
                                        <MenuItem value={0}>INATIVO</MenuItem>
                                    </Select>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    <ButtonGroup aria-label="outlined primary button group">
                                        <Button color="primary" type="submit">Salvar Valores</Button>
                                    </ButtonGroup>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}