import React , {useState, useEffect}from 'react';
//import {useHistory} from 'react-router-dom';

import '../styles/pages/hitAdmin.css';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';

import api from "../services/api";
import iOrcamento from '../interfaces/iOrcamento';

export default function Propostas() {
    const useStyles = makeStyles({
        table: {
        minWidth: 650,
        },
        root: {
            width: '100%',
        },
        container: {
            maxHeight: '740px',
        },
  });
const classes = useStyles();
const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(25);
const handleChangePage = (event:any, newPage:any) => {
    setPage(newPage);
  };

const handleChangeRowsPerPage = (event: any) => {
setRowsPerPage(+event.target.value);
setPage(0);
};

const [propostas, setPropostas] = useState<iOrcamento[]>([]);

useEffect(() => {
    api.get(`Orcamento/ObterOrcamentosGet`).then(response => {
        setPropostas(response.data);
        //console.log(response.data);
    })
}, []);

if (!propostas){
    return <p>Carregando...</p>;
}

    return (
        <div className="admin-window">
            <Header />
            <div className="content-main">
                <Sidebar />
                <div className="content-area">
                    <div>PROPOSTAS</div>
                    <br /><hr /><br />
                    <div>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container} component={Paper}>
                            <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Controle</TableCell>
                                        <TableCell align="left">Nome</TableCell>
                                        <TableCell align="left">Empresa</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="center">Telefone</TableCell>
                                        <TableCell align="right">Valor da Proposta</TableCell>
                                        <TableCell align="center">Data da Proposta</TableCell>
                                        <TableCell align="center">Posição</TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {propostas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((propostas) =>(
                                        <TableRow hover role="checkbox" tabIndex={-1} key={propostas.IdOrcamento}>
                                            <TableCell component="th" scope="row">
                                                {propostas.controle? propostas.controle : "Não Informado"}
                                            </TableCell>
                                            <TableCell align="left">{propostas.nome? propostas.nome:"Não Informado"}</TableCell>
                                            <TableCell align="left">{propostas.empresa? propostas.empresa:"Não Informado"}</TableCell>
                                            <TableCell align="left">{propostas.email? propostas.email:"Não Informado"}</TableCell>
                                            <TableCell align="center">{propostas.telefone? propostas.telefone:"Não Informado"}</TableCell>
                                            <TableCell align="right">{propostas.valorTotal? propostas.valorTotal:"Não Informado"}</TableCell>
                                            <TableCell align="center">{propostas.criadoEm? propostas.criadoEm:"Não Informado"}</TableCell>
                                            <TableCell align="center">{propostas.posicao? <Chip label={propostas.posicao} color="primary" /> :"Não Informado"}</TableCell>
                                            <TableCell align="center">
                                            <ButtonGroup aria-label="outlined primary button group">
                                                <Button color="primary" href={`/Orcamento/${propostas.codigoGUID}`} target="_blank" >Proposta</Button>
                                                {/* <Button color="secondary">Posição</Button> */}
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={propostas.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    </Paper>
                    <br></br><br></br>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
}