import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/pages/Orcamento.css';
import imgLogo from '../images/logo-hfit.png';
import imgPag2 from '../images/fundo-orcamento-p2.png';
import imgHeader from '../images/header-orcamento.jpg';
import imgApresentacao from '../images/fundo-apresentacao.png';
import imgClassificacao from '../images/classificacao-nivel.png';

import api from "../services/api";
import iOrcamento from '../interfaces/iOrcamento';

interface OrcamentoParams {
    id: string;
}

export default function Orcamento() {
    const params = useParams<OrcamentoParams>();
    const [orcamentoAtual, setOrcamentoAtual] = useState<iOrcamento>();

    useEffect(() => {
        api.get(`Orcamento/ObterOrcamentosGet?g=${params.id}`).then(response => {
            setOrcamentoAtual(response.data[0]);
        })
    }, [params.id]);

    // console.log(params.id);

    if (!orcamentoAtual) {
        return <p>Carregando...</p>;
    }
    // console.log(orcamentoAtual);

    return (
        <div id="page-orcamento">
            <div className="content-wrapper">
                <div className="header">
                    <div className="div-header-1">
                        <div>
                            <img className="img-logo" src={imgLogo} alt="" />
                        </div>
                        <div>
                            <label className="header-titulo-1" >Atendimento remoto eficaz</label>
                        </div>
                        <div>
                            <label className="header-titulo-2" >rápido e qualificado</label>
                        </div>
                    </div>
                    <div className="div-header-2">
                        <div>
                            <h1>{orcamentoAtual.empresa !== "" ? 'À ' + orcamentoAtual.empresa : ''}</h1>
                            <h2>Assunto: Plano de Atendimento em Informática - H F.Ti</h2>
                            <h2>{orcamentoAtual.nome !== "" ? 'A/c: ' + orcamentoAtual.nome : ''}</h2>
                            <br></br>
                            <h3>{orcamentoAtual.controle? orcamentoAtual.controle : ''}</h3>
                        </div>
                    </div>

                </div>
                <div className="bar-header">
                    <img className="imageHeaderOrcamento" src={imgHeader} alt="" />
                </div>

                <div className="form-group">

                    <div className='orcamentoApresentacao'>
                        <p className='paragrafo'>
                            A Humanit apresenta uma proposta inovadora para prestação de serviços em tecnologia da informação, com os programas de atuação descritos abaixo.
            </p>
                        <p className='paragrafo'>
                            Temos um compromisso com você e prestaremos os melhores serviços, alinhados às necessidades e expectativas apresentadas, aliando competências técnicas e vasta experiência, com uma equipe que entrega resultados eficientes e com base em comportamentos éticos.
            </p>
                        <p className='paragrafo'>
                            Dessa forma, agradecemos a oportunidade e nos colocamos à disposição para qualquer esclarecimento.
            </p>
                        <p className='paragrafoDestaque'>
                            Atenciosamente,<br />
              Mauricio Bertoldi <br />
              11 99234-1922 <br /> mbertoldi@humanit-ti.com
            </p>
                        <img className="imgPag1" src={imgApresentacao} alt="" />
                    </div>

                    <div className='orcamentoPagina2'>
                        <p className='paragrafoDestaque'>
                            CONFIDENCIALIDADE:
            </p>
                        <p className='paragrafo'>
                            Esta proposta contempla informações que devem ser tratadas apenas pelas partes envolvidas.
            </p>
                        <p className='paragrafo'>
                            O modelo de atuação, aqui apresentado, faz parte do desenvolvimento e estratégias da Humanit, procedente de processos de pesquisa e escuta junto ao nosso mercado de atuação.
            </p>
                        <p className='paragrafo'>
                            Caso não ocorra a contratação dos serviços da presente proposta, a Humanit informa que as informações contidas nesse material, são de sua propriedade intelectual e não podem ser divulgadas.
            </p>
                        <p className='paragrafo'>
                            Da mesma forma, a Humanit se compromete a manter sigilo absoluto, de toda e qualquer informação técnica e/ou comercial, que venha a conhecer acerca do cliente, bem como projetos, equipamentos e instalações relacionados com os serviços que lhe forem confiados.
            </p>
                        <p className='paragrafo'>
                            Esta é uma proposta preliminar, que foi elaborada com base em informações inseridas pelo próprio cliente. A Humanit se reserva o direito de, caso ocorra a contratação comercial, efetuar auditoria e conferir os itens que impactam no valor dos serviços, seguindo a régua de cálculo disponibilizada no site da Humanit, podendo efetuar ajustes no valor da prestação de serviços.
            </p>
                        <p className='paragrafo'>
                            Esta proposta não obriga à contratação dos serviços por parte do cliente.
            </p>

                        <img className="imgPag2" src={imgPag2} alt="" />
                    </div>

                    <div className='orcamentoPagina3'>

                        <p className='paragrafoDestaque'>
                            A Humanit:
          </p>
                        <p className='paragrafoDestaque'>
                            EMPRESA PROGRESSISTA:
          </p>
                        <p className='paragrafo'>
                            Resolvemos, após muitos e muitos anos de aprendizado, somados aos processos de aperfeiçoamento de conceitos em ser uma empresa progressista, trabalhando para ajudar na construção de mercados éticos. Focamos nossas atitudes na colaboração, ética, transparência e, sobretudo, na melhor condição do comportamento humano, pois acreditamos que as habilidades técnicas estão a serviço das habilidades humanas.
          </p>
                        <p className='paragrafo'>
                            Conquistamos um espaço de trabalho fundamentado em valores virtuosos e proporcionamos essa experiência a todo o holograma de mercado (parceiros, governo, clientes, acionistas, equipe). Recebemos, em troca, a riqueza de construir, além de clientes fidelizados, uma saudável rede de relacionamentos.
          </p>
                        <p className='paragrafoDestaque'>
                            OBJETIVO:
          </p>
                        <p className='paragrafo'>
                            Plano de atendimento em informática para empresas, considerando redes de computadores e usuários, provendo atendimento focado e completo para a continuidade de utilização de suas redes de computadores
          </p>
                        <p className='paragrafo'>
                            <li>Continuidade da operação;</li>
                            <li>Disponibilidade das informações;</li>
                            <li>Atendimentos remotos e emergenciais in loco;</li>
                            <li>Atendimento em curto espaço de tempo;</li>
                            <li>Segurança e disponibilização da informação;</li>
                            <li>Monitoramento dos recursos de rede;</li>
                            <li>Competência técnica comprovada;</li>
                            <li>Equipe treinada nas frentes técnicas e comportamentais;</li>
                            <li>Atendimentos humanos e éticos;</li>
                            <li>Foco em micro e pequenas empresas de até 100 usuários;</li>
                            <li>Baixo custo para os clientes;</li>
                            <li>Foco na disponibilidade da rede e parque de computadores;</li>
                            <li>Simulação dos planos de atendimento diretamente no site.</li>
                        </p>

                    </div>

                    <div className="paginaEscopo">

                        <p className='paragrafoDestaque'>
                            ESCOPO DE ATENDIMENTO:
            </p>

                        <p className='paragrafoDestaque'>
                            1 – Servidores
            </p>
                        <p className='paragrafoDestaque'>
                            2 – Proteções:
            </p>
                        <p className='paragrafo'>
                            Fornecimento em comodato de proteções para servidores - firewall Humanit (hardware e configurações) e anti-vírus de rede
            </p>
                        <p className='paragrafo'>
                            <span className="negrito">OBS: </span> as proteções oferecidas em comodato aos clientes da Humanit, não garantem que não possam ocorrer ataques de hackers e criptografia dos dados dos clientes. Nossas proteções visam aumentar o grau de dificuldade dos ataques dos hackers, por meio de soluções consolidadas, mas não há garantia de que não ocorram invasões ou criptografia dos dados.
            </p>
                        <p className='paragrafoDestaque'>
                            3 – Notebooks / Desktops / Macs
            </p>
                        <p className='paragrafoDestaque'>
                            4 – Soluções de e-mails - Lado provedor / Lado Cliente
            </p>
                        <p className='paragrafoDestaque'>
                            5 – Impressoras
            </p>
                        <p className='paragrafoDestaque'>
                            6 – WIFI
            </p>
                        <p className='paragrafoDestaque'>
                            7 – Links de Internet
            </p>
                        <p className='paragrafoDestaque'>
                            8 – Massa de dados para replicação em nuvem da Humanit
            </p>
                        <p className='paragrafo'>
                            <span className="negrito">OBS: </span> cliente deve disponibilizar área para backup local (pasta de arquivos em servidor ou HD), que servirá de base para o backup da solução Humanit.
            </p>
                        <p className='paragrafo'>
                            <li>Backup full de até 1000 GB (1TB) para o primeiro backup – feito 1 vez e efetuado no primeiro final de semana, após a contratação do plano. Necessário janela de 48h, links com taxas de transmissão que atendam a transferência da massa de dados full na janela de 48h;</li>
                            <li>Backup incremental diário.</li>
                        </p>
                        <p className='paragrafo'>
                            <span className="negrito">OBS: </span> quando a massa de dados ultrapassar 1000 GB (1TB), será cobrado valor adicional.
            </p>

                    </div>

                    <div className="paginaClassificacao">
                        <p className='paragrafoDestaque'>
                            CLASSIFICAÇÃO DOS NÍVEIS DE ATENDIMENTO:
            </p>
                        <p className='paragrafo'>
                            <span className="negrito">Nível 1:</span> Atendimento ao usuário de desktops, notebooks relacionados à softwares (sistema operacional, pacote office, sistemas bancários, softwares e apoio – Java, Acrobat, Chrome).
            É caracterizado pelo processo de atendimento e finalização com 100% da solução, feito com atendimento remoto (comunicação por abertura de chamado na solução de help desk da Humanit).
            </p>
                        <p className='paragrafo'>
                            <span className="negrito">Nível 2:</span>Atendimento ao usuário de desktops, notebooks relacionados à softwares e hardwares que não puderem ser resolvidos pela equipe nível 1.
            É caracterizado pelo processo de atendimento e finalização de ocorrências complexas.
            </p>
                        <p className='paragrafo'>
                            <span className="negrito">Nível 3:</span> Atendimento a redes, servidores, datacenters, segurança, backup e back bone. É caracterizado pelo processo de gerenciamento de redes e servidores, administração de backups e gerenciamento de acessibilidade.
            </p>
                        <p className='paragrafo'>
                            <span className="negrito">OBS:</span> todos os chamados devem ser abertos e gerenciados pela solução de help desk da Humanit.
            </p>
                        <div className="pImg">
                            <img className="imgPagClass" src={imgClassificacao} alt="" />
                        </div>
                    </div>

                    <div className="paginaValores">

                        <p className='paragrafoDestaque'>Atividades para iniciar um novo plano de atendimento: pré-requisitos</p>

                        <p className='paragrafo'>1. Checar infraestrutura do cliente para elaboração do contrato de prestação de serviços;</p>
                        <p className='paragrafo'>2. Envio de minuta e assinatura do contrato;</p>
                        <p className='paragrafo'>3. Documentação da infraestrutura do cliente e abertura do cliente no help desk;</p>
                        <p className='paragrafo'>4. Agendamento do início do contrato e instalação e configuração do comodato do firewall;</p>
                        <p className='paragrafo'>5. Instalação e configuração do antivírus na rede do cliente;</p>
                        <p className='paragrafo'>6. Agendamento e preparo da primeira replicação da massa de dados (backup full);</p>
                        <p className='paragrafo'>7. Determinação do interlocutor do cliente para atividades hands-on.</p>

                        <p className='paragrafoDestaque'>Infraestrutura de informática para este plano de atendimento:</p>

                        <p className='paragrafoDestaque'>1 – Quantidade de servidores (físico, virtual, nuvem):</p>
                        <p className='paragrafoItem'>{orcamentoAtual.servidoresWindows} – Windows</p>
                        <p className='paragrafoItem'>{orcamentoAtual.servidoresLinux} – Linux</p>
                        <div className="divResumoItem">
                            <p className='paragrafoItem'>Total de servidores: {Number(orcamentoAtual.servidoresWindows) + Number(orcamentoAtual.servidoresLinux)} servidores</p>
                        </div>

                        <p className='paragrafoDestaque'>2 – Quantidade de notebooks / desktops / macs: limite máximo:</p>
                        <p className='paragrafoItem'>{orcamentoAtual.desktopWindows} – Windows</p>
                        <p className='paragrafoItem'>{orcamentoAtual.desktopLinux} – Linux</p>
                        <p className='paragrafoItem'>{orcamentoAtual.desktopMac} – Macs</p>
                        <div className="divResumoItem">
                            <p className='paragrafoItem'>Total de computadores: {Number(orcamentoAtual.desktopWindows) + Number(orcamentoAtual.desktopLinux) + Number(orcamentoAtual.desktopMac)} computadores</p>
                        </div>

                        <p className='paragrafoDestaque'>3 – Quantidade de contas de e-mail: limite máximo:</p>
                        <div className="divResumoItem">
                            <p className='paragrafoItem'>{orcamentoAtual.contasDeEmail} contas de e-mail</p>
                        </div>

                        <p className='paragrafoDestaque'>4 – Quantidade de impressoras: limite máximo:</p>
                        <div className="divResumoItem">
                            <p className='paragrafoItem'>{orcamentoAtual.impressoras} impressoras ativas</p>
                        </div>

                        <p className='paragrafoDestaque'>5 – Quantidade de antenas WiFi: limite máximo:</p>
                        <p className='paragrafoItem'> Antenas dos fabricantes:
            {/* {orcamentoAtual.antenasWiFi !== "0" ?  */}
                            {orcamentoAtual.antenaDLink === "1" ? ' D-Link,' : ''}
                            {orcamentoAtual.antenaTPLink === "1" ? ' TP-Link,' : ''}
                            {orcamentoAtual.antenaIntelbras === "1" ? ' Intelbras,' : ''}
                            {orcamentoAtual.antenaAsus === "1" ? ' Asus,' : ''}
                            {orcamentoAtual.antenaUnifi === "1" ? ' Unifi,' : ''}
                            {orcamentoAtual.antenaOutros === "1" ? ' ' + orcamentoAtual.antenaOutros : ''}
                            {/* : ''} */}
                        </p>

                        <div className="divResumoItem">
                            <p className='paragrafoItem'>{orcamentoAtual.antenasWiFi} antenas de WiFi</p>
                        </div>

                        <p className='paragrafoDestaque'>6 – Quantidade de massa de dados para backup full e incremental:</p>
                        <div className="divResumoItem">
                            <p className='paragrafoItem'>{orcamentoAtual.massaDeDados} GB (1 TB)</p>
                        </div>

            Obs: cliente deve disponibilizar área para backup local, que servirá de base para o backup
            da solução Humanit.

            <p className='paragrafoDestaque'>7 – Quantidade de links de internet:</p>
                        <p className='paragrafoItem'> Links dos fornecedores:
            {orcamentoAtual.linkVivoFibra === "1" ? ' Vivo Fibra,' : ''}
                            {orcamentoAtual.linkClaroNet === "1" ? ' Claro Net,' : ''}
                            {orcamentoAtual.linkTIMFibra === "1" ? ' TIM Fibra,' : ''}
                            {orcamentoAtual.linkDedicadoVivo === "1" ? ' Link Dedicado Vivo,' : ''}
                            {orcamentoAtual.linkDedicadoAlgar === "1" ? ' Link Dedicado Algar,' : ''}
                            {orcamentoAtual.linkDedicadoEmbratel === "1" ? ' Link Dedicado Embratel' : ''}
                            {orcamentoAtual.linkDedicadoMundivox === "1" ? ' Link Dedicado Mundivox' : ''}
                            {orcamentoAtual.linkDedicadoTelium === "1" ? ' Link Dedicado Telium' : ''}
                            {orcamentoAtual.linkDedicadoOutros === "1" ? ' Link Dedicado Outros' : ''}
                        </p>
                        <div className="divResumoItem">
                            <p className='paragrafoItem'>{orcamentoAtual.quantidadeLinks} links de internet</p>
                        </div>
            A Humanit possui um grupo de trabalho on-line que atende às necessidades e dificuldades encontradas pela equipe dos clientes, durante os dias de trabalho, proporcionando agilidade na busca de soluções

            <p className='paragrafoDestaque'>Disponibilidade para atendimentos aos chamados:</p>

                        <p className='paragrafo'>
                            <li>Help Desk para atendimento remoto em horário comercial: 9h00 às 18h00,</li>
                            <li>(segunda a sexta-feira).</li>
                            <li>Visitas agendadas: horário comercial: 9h00 às 18h00</li>
                            <li>(segunda a sexta-feira).</li>
                        </p>

                        <p className='paragrafo'>
                            <span className="negrito">Líder Técnico (Administrador de Redes)</span>
               - emergências e reuniões de gestão e apresentação de relatórios – visitas agendadas
            </p>

                        <p className='paragrafo'>
                            <span className="negrito">Gestor do Cliente (demanda flexível)</span> - visitas agendadas
            </p>

                        <div className="divResumoItem">
                            <p className='paragrafoItem'>Valor mensal: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(orcamentoAtual.valorTotal))} </p>
                            {/* .ToFixed(2) */}
                        </div>

                        <p className='paragrafo'>
                            <span className="negrito">Horas adicionais:</span> (após as 18h00, sábados, domingos e feriados).
            </p>
                        <p className='paragrafo'>
                            <span className="negrito">Projetos e agendamentos fora do horário comercial:</span>
                        </p>
                        <p className='paragrafo'>
                            <li><span className="negrito">Lider de equipe:</span> R$ 200,00 / hora</li>
                            <li><span className="negrito">Administrador de rede:</span> R$ 180,00 / hora</li>
                            <li><span className="negrito">Analista de suporte:</span> R$ 140,00 / hora</li>
                        </p>

                        <p className='paragrafoDestaque'>
                            Não estão inclusos projetos que demandem equipe e horário adicional.
            </p>

                        <p className='paragrafoDestaque'>
                            Obs: todo final de mês, o inventariado do parque de informática do cliente será atualizado e, caso venha apresentar crescimento em sua infraestrutura de informática nos tópicos: servidores, computadores (notebooks, desktops, macs), antenas Wifi, links de internet, impressoras, contas de e-mail, o valor da prestação de serviços será reajustado e o novo valor passará a vigorar no mês subsequente do crescimento comprovado. O mesmo processo ocorrerá caso o cliente apresente decréscimo em sua infraestrutura de rede e parque de informática. Este processo de acréscimo ou diminuição do modelo de atendimento serão dimensionados por meio das réguas de dimensionamento de valores constantes, no site da Humanit.
            </p>

                        <div className='paginaConsideracoes'>

                            <p className='paragrafoDestaque'>Validade da proposta: 10 dias corridos.</p>

                            <p className='paragrafo'>
                                Incluem nos valores mencionados, todos os encargos tributários e fiscais, relativos à prestação de serviços. Todos os demais recolhimentos, por se tratar de contrato exclusivo de prestação de serviços, serão realizados pela CONTRATADA, ao que lhe convier e pelo Contratante ao que lhe couber.
              </p>

                            <p className='paragrafoDestaque'>
                                CONSIDERAÇÕES:
              </p>

                            <p className='paragrafo'>
                                O início das atividades se dará a partir de 15 dias corridos do aceite da proposta.
              </p>
                            <p className='paragrafo'>
                                A Humanit se compromete a manter sigilo e confidencialidade sobre todas as informações, dados e documentos do cliente, sobre pena de infração contratual e rescisão automática desse contrato, sem prejuízo de reparação por dados materiais e morais.
              </p>
                            <p className='paragrafo'>
                                Tempo mínimo de contrato: 1 ano com prorrogação automática por igual período salvo, se uma das partes se manifestar formalmente, considerando índice de reajuste atrelado ao IGPM.
              </p>
                            <p className='paragrafo'>
                                Carência para destrato antes do término do contrato: pro rata até completar 12 meses de contrato.
              </p>
                            <p className='paragrafo'>
                                Após 12 meses da assinatura do contrato, o mesmo poderá ser rescindido por ambas as partes, com a prévia comunicação por escrito. Carência para distrato após 12 meses de contrato: 30 dias corridos.
              </p>
                            <p className='paragrafo'>
                                Todos os encargos legais e trabalhistas ficam sob responsabilidade da Humanit.
              </p>
                            <p className='paragrafo'>
                                Em caso de término ou rescisão deste contrato, por qualquer motivo ou razão, as partes concordam em devolver imediatamente uma a outra todos os materiais que constituam e/ou incorporem informações confidenciais ou propriedade intelectual ou destruí-los, desde que a proprietária autorize.
              </p>
                            <p className='paragrafo'>
                                A Humanit é inteiramente responsável pela cabal, eficiente e satisfatória execução dos trabalhos, objeto dessa oferta, responsabilizando-se por quaisquer incorreções ou falhas técnicas nos trabalhos executados no âmbito desta prestação, bem como pela guarda e segurança de todos os documentos necessários ao desenvolvimento dos trabalhos.
              </p>
                            <p className='paragrafo'>
                                O cliente fica impedido de absorver ou contratar qualquer prestador de serviços da Humanit que efetivamente prestarem serviços na contratada pelo prazo de 3 (três) anos, a partir do término da prestação de serviços para a contratante. Caso a Contratante apresente intenção de contratar colaboradores da Contratada, deve encaminhar ofício demonstrando tal intenção, declarando as condições de contratação, tal como modalidade, salário, benefícios. A Contratada se manifestará em até 15 dias corridos, podendo ou não atender à solicitação da Contratante.
              </p>
                            <p className='paragrafo'>
                            </p>
                        </div>

                        <div className="formularioAceite">

                            <p className='paragrafoDestaque'>DE ACORDO:</p>

                            <p className='paragrafo'>Estamos confirmando a aceitação dessa proposta pelo qual solicitamos
                            o encaminhamento da minuta do contrato, com as condições específicas, relacionadas aos serviços
                            acima ofertados:
              </p>

                            <div className="linhaForm">
                                <p className='paragrafo'>Razão Social:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>Endereço:</p>
                            </div>
                            <div className="linhaFormCol">
                                <div className='paragrafoFormCol cidade'>Cidade:</div>
                                <div className='paragrafoFormCol estado'>Estado:</div>
                                <div className='paragrafoFormCol cep'>CEP:</div>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>Teledone:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>Fax:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>CNPJ:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>IE:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>Responsável:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>Cargo:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>Email:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>Celular:</p>
                            </div>
                            <div className="linhaForm">
                                <p className='paragrafo'>Assinatura:</p>
                            </div>

                            <div className="linhaFormCol">
                                <div className='paragrafoFormCol local'>Local:</div>
                                <div className='paragrafoFormCol data'>Data:</div>
                            </div>


                        </div>

                    </div>

                </div>
            </div>

        </div>
    );
}
