import React , {useState, useEffect}from 'react';

import '../styles/pages/hitAdmin.css';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';

import api from "../services/api";
import iValoresAdicionais from "../interfaces/iValoresAdicionais";

export default function ValoresAdicionais() {
    const useStyles = makeStyles({
        table: {
        minWidth: 650,
        },
        root: {
            width: '100%',
        },
        container: {
            maxHeight: '740px',
        },
    });
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const handleChangePage = (event:any, newPage:any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };

    const [valoresAdicionais, setValoresAdicionais] = useState<iValoresAdicionais[]>([]);

    useEffect(() => {
        api.post(`Orcamento/ObtemValoresAdicionais`).then(response => {
            setValoresAdicionais(response.data);
            console.log(response.data);
        })
    },[]);

    if (!valoresAdicionais){
        return <p>Carregando...</p>;
    }

    return (
        <div className="admin-window">
            <Header />
            <div className="content-main">
                <Sidebar />
                <div className="content-area">
                    <div>TABELA DE VALORES DE ITENS ADICIONAIS</div>
                    <br /><hr /><br />
                    <ButtonGroup aria-label="outlined primary button group">
                        <Button color="primary" href={`/ValoresAdicionais/Cadastrar`} >Cadastrar Novo</Button>
                    </ButtonGroup>
                    <br /><br />
                    <div>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container} component={Paper}>
                            <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell align="center">ID</TableCell>
                                        <TableCell align="left">Descrição do Item</TableCell>
                                        <TableCell align="center">Valor do Item</TableCell>
                                        <TableCell align="center">Posição</TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {valoresAdicionais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((valores) =>(
                                        <TableRow hover role="checkbox" tabIndex={-1} key={String(valores.IdValorAdicional)}>
                                            <TableCell align="center" component="th" scope="row">
                                                {valores.IdValorAdicional? valores.IdValorAdicional : "0"}
                                            </TableCell>
                                            <TableCell align="left">{valores.descricaoItem? valores.descricaoItem:"0"}</TableCell>
                                            <TableCell align="center">{valores.valorItem? valores.valorItem.toFixed(2):"0"}</TableCell>
                                            <TableCell align="center">{valores.posicao === "ATIVO"? <Chip label={valores.posicao} color="primary" /> : <Chip label={valores.posicao} color="secondary"></Chip>}</TableCell>
                                            <TableCell align="center">
                                            <ButtonGroup aria-label="outlined primary button group">
                                                <Button color="primary" href={`/ValoresAdicionais/Editar/${valores.IdValorAdicional}`} >Editar</Button>
                                                {/* <Button color="secondary">Posição</Button> */}
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={valoresAdicionais.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    </Paper>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}