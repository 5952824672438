import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/NotFound.css';
const NotFound = () => (
  <div id="page-notfound">
    <Link className="link" to="/">
      <h1>404 - Lamentamos, o endereço que está tentando acessar não existe, tente novamente ou volte ao inicio!</h1>
    </Link>
  </div>
);

export default NotFound;