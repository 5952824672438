import React from 'react';
import '../styles/components/Sidebar.css'
import logoHit from '../images/icon_hit.png';
import logoHitG from '../images/loading.gif';
import { Link } from 'react-router-dom';


export default function Sidebar() {
    return (
        <div className="sidebar">
            <header className="sidebar-header">
                <Link to="/HITAdmin" >
                <img className="sidebar-header-img" src={logoHit} alt="HITAdmin" />
                </Link>
                <div className="sidebar-header-buttons" >
                </div>
            </header>

            <div className="menulist">
                <Link to="/Propostas">

                <button
                type="button"
                className="btnMenu">Propostas</button>
                </Link>

                <Link to="/ValoresTabela">
                <button
                type="button"
                className="btnMenu">Valores Itens</button>
                </Link>

                <Link to="/ValoresVisitas">
                <button
                type="button"
                className="btnMenu">Valores Visitas</button>
                </Link>

                <Link to="/ValoresAdicionais">
                <button
                type="button"
                className="btnMenu">Valores Adicionais</button>
                </Link>
            </div>

        </div>
    );
}
