import React , {useState, useEffect}from 'react';
import '../styles/pages/hitAdmin.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';


export default function HITAdmin() {
    return (
        <div className="admin-window">
            <Header />
            <div className="content-main">
                <Sidebar />
                <div className="content-area">
                    <div>Bem Vindo!</div>
                    <hr></hr>
                    <div></div>
                </div>
            </div>
            <Footer />
        </div>
    );
}