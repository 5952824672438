import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Landing from './pages/Landing';
import NotFound from './pages/NotFound';
import CreateOrcamento from './pages/CreateOrcamento';
import Orcamento from './pages/Orcamento';
import HitAdmin from './pages/HITAdmin';
import ValoresTabela from './pages/ValoresTabela';
import ValoresVisitas from './pages/ValoresVisitas';
import ValoresVisitasEditar from './pages/ValoresVisitasEditar';
import ValoresAdicionais from './pages/ValoresAdicionais';
import ValoresAdicionaisEditar from './pages/ValoresAdicionaisEditar';
import ValoresAdicionaisCadastro from './pages/ValoresAdicionaisCadastro';
import Propostas from './pages/Propostas';

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/Orcamento/:id" component={Orcamento} />
        <Route path="/CreateOrcamento" component={CreateOrcamento} />
        <Route path="/HITAdmin" exact component={HitAdmin} />
        <Route path="/ValoresTabela" component={ValoresTabela} />
        <Route path="/ValoresVisitas" exact component={ValoresVisitas} />
        <Route path="/ValoresVisitas/Editar/:id" component={ValoresVisitasEditar} />
        <Route path="/ValoresAdicionais" exact component={ValoresAdicionais} />
        <Route path="/ValoresAdicionais/Editar/:id" component={ValoresAdicionaisEditar} />
        <Route path="/ValoresAdicionais/Cadastrar" component={ValoresAdicionaisCadastro} />
        <Route path="/Propostas" component={Propostas} />
        
        {/* <Route path="/Orcamento" component={Orcamento} /> */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default Routes;